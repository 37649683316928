import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import PaintingGrid from '../components/paintings/paintingGrid'

export default function AvailableList({ data, pageContext: {title, paintings, availableYear } }) {
  const year = title;
  const images = data.images.nodes
  return (
    <Layout current={year}>
    <main>
      <div>
        <PaintingGrid timelineNull available={!!availableYear} images={images} title={year} paintings={paintings} />
      </div>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query{

          images: allS3Object {
            nodes {
              Key
              localFile {
                  relativePath
                  childImageSharp {
                      gatsbyImageData(
                        width: 800
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        formats: [JPG]
                      )
                    }
                }
              }
            }
          }
  
`;

// yearsJson( title: { eq: $title} ) {
//   title
// }
// allPaintingsJson (sort: {order: ASC, fields: title}, filter: {year : {eq: $title}}) {
//       nodes {
//         id,
//         title,
//         filepath
//       }
//   }
