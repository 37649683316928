import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby"
import * as gridStyles from "./paintingGrid.module.css";

import { myContext } from '.././PageContext';
import { BiDotsVertical } from 'react-icons/bi'

export default function PaintingGrid(props) {


  const gridItems = props.paintings.map((painting) => {
    const image = props.images.filter(item => item.Key === painting.filepath)
    return (
      <Link 
      id={`${painting.title.toLowerCase().replace(/ /g,"-")}`} 
      to={props.available ? `/available/${painting.title.toLowerCase().replace(/ /g,"-")}` : `/${painting.title.toLowerCase().replace(/ /g,"-")}`} 
      className={gridStyles.cell}>
        {image.length === 1 ? <GatsbyImage
          style={{ position: "absolute", minHeight: '100%', minWidth: '100%'}}
          image={getImage(image[0].localFile)}
          alt={"placeholder"}
        />
        : null
        }
        <span>{props.main ? painting.year : painting.title}</span>
      </Link>
    );
  });

  return (
    <myContext.Consumer>
    {context => (
    <>
      <div className={gridStyles.header}>
        {
        props.timelineNull ? null :
        <div style={{zIndex: 2, position: 'absolute', top: 0, left: 0, height: '100%'}}>
            <button onClick={() => context.toggleSideNav()} className={"timeline_btn"}><BiDotsVertical/>timeline</button>        
        </div>
        }
        <h2>{props.title}</h2>
      </div>
      <div className={gridStyles.grid}>{gridItems}</div>
    </>
    )}
    </myContext.Consumer>
  );
}
